@import url('https://fonts.googleapis.com/css2?family=Ibarra+Real+Nova:wght@400;500;700&family=Red+Hat+Display:wght@400;600;800;900&display=swap');

*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
img {
  max-width: 100%;
  display: block;
}
.container {
  max-width: 1514px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 14px;
  overflow: hidden;
  position: relative;
}
:root {
  /* Colors: */
  --white: #ffffff;
  --neutral-02: #f7f6f9;
  --secondary-03: #efefe7;
  --secondary-02: #e3dcd2;
  --unnamed-color-a7dce7: #a7dce7;
  --primary-03: #9ecfe4;
  --primary-02: #5798c1;
  --neutral: #191919;
  --secondary: #e9e8dc;
  --primary: #00a4e3;
  --unnamed-color-535353: #535353;

  /* Font/text values */
  --unnamed-font-family-ibarra-real-nova: Ibarra Real Nova;
  --unnamed-font-family-red-hat-display: Red Hat Display;
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-normal: normal;
  --unnamed-font-weight-medium: medium;
  --unnamed-font-size-12: 12px;
  --unnamed-font-size-14: 14px;
  --unnamed-font-size-24: 24px;
  --unnamed-font-size-28: 28px;
  --unnamed-font-size-32: 32px;
  --unnamed-font-size-48: 48px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-character-spacing-1-8: 1.8px;
  --unnamed-line-spacing-22: 22px;
  --unnamed-line-spacing-35: 35px;
  --unnamed-line-spacing-40: 40px;
  --unnamed-line-spacing-42: 42px;
  --unnamed-line-spacing-60: 60px;
  --unnamed-line-spacing-25-200000762939453: 25.200000762939453px;
  --unnamed-text-transform-uppercase: uppercase;
}

/* Character Styles */
.mobile-section-header {
  font-family: var(--unnamed-font-family-ibarra-real-nova);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-28);
  line-height: var(--unnamed-line-spacing-35);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--neutral);
}
button {
  border: none;
  cursor: pointer;
}
.btn-cta {
  font-family: var(--unnamed-font-family-red-hat-display);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-12);
  line-height: var(--unnamed-line-spacing-22);
  letter-spacing: var(--unnamed-character-spacing-1-8);
  color: var(--neutral);
  text-transform: var(--unnamed-text-transform-uppercase);
  background-color: var(--primary-03);
  padding: 15px 30px 12px;
  transition: 0.3s ease-in-out;
}
.btn-cta:hover {
  background-color: var(--primary);
  color: var(--neutral-02);
  transition: 0.3s ease-in-out;
}
.btn-shop-now {
  font-size: var(--unnamed-font-size-12);
  color: var(--neutral);
  font-family: var(--unnamed-font-family-red-hat-display);
  line-height: 22px;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  text-decoration: underline;
  background-color: white;
  text-align: center;
  transition: 0.3s ease-in-out;
}
.btn-shop-now:hover {
  color: var(--primary);
  transition: 0.3s ease-in-out;
}
.h1 {
  font-family: var(--unnamed-font-family-ibarra-real-nova);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-48);
  line-height: var(--unnamed-line-spacing-60);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--neutral);
}
.h2 {
  font-family: var(--unnamed-font-family-ibarra-real-nova);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-32);
  line-height: var(--unnamed-line-spacing-40);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--neutral);
}
body {
  font-family: var(--unnamed-font-family-red-hat-display);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  line-height: var(--unnamed-line-spacing-25-200000762939453);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--neutral);
  font-size: 1.5rem;
  overflow-x: hidden;
}
a {
  text-decoration: none;
  font-size: var(--unnamed-font-size-14);
  line-height: 25.2px;
  color: var(--neutral);
}
@media (min-width: 767px) {
  body {
    font-size: 1.6rem;
  }
}
.secondary {
  font-family: var(--unnamed-font-family-red-hat-display);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-24);
  line-height: var(--unnamed-line-spacing-42);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-535353);
}
.primary {
  font-family: var(--unnamed-font-family-ibarra-real-nova);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-24);
  line-height: var(--unnamed-line-spacing-42);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-535353);
}

/* css main */

/* UtilityContent */
.UtilityContent {
  background-color: var(--neutral-02);
}
.UtilityContent p {
  font-family: var(--unnamed-font-family-red-hat-display);
  font-size: var(--unnamed-font-size-12);
  letter-spacing: 1.8px;
  line-height: 16px;
  color: var(--neutral);
  text-transform: uppercase;
  text-align: center;
  padding: 10px;
}
.UtilityContent p span {
  font-weight: bold;
}

/* navbar */
.nav-hamburger svg {
  height: 2rem;
  width: 2rem;
  fill: none;
  vertical-align: middle;
}
.Brandlogo a {
  display: block;
}
.Brandlogo img {
  cursor: pointer;
}
.Nav-container ul {
  display: flex;
  list-style-type: none;
}
.Nav-links li a {
  font-family: var(--unnamed-font-family-red-hat-display);
  text-decoration: none;
  font-size: var(--unnamed-font-size-14);
  letter-spacing: 1.4px;
  line-height: 18px;
  color: var(--neutral);
  padding: 1.2rem;
}
.header-drawer {
  display: block;
}
.Nav-container {
  display: grid;
  align-items: center;
  padding: 30px 0;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 2rem;
}
.Nav-right-con {
  display: flex;
  justify-self: end;
  align-items: center;
}
.header_icons {
  height: 2rem;
  width: 2rem;
  align-items: center;
  justify-content: center;
  display: flex;
  box-shadow: none;
  border: none;
}
.Navlinks-container {
  display: none;
}
.Nav-right-con a:nth-child(3) {
  display: none;
}
.Nav-right-con svg {
  width: 2rem;
  cursor: pointer;
}

@media screen and (max-width: 1023px) {
  header .container {
    overflow: unset;
  }
  .menu-drawer {
    position: absolute;
    transform: translate(-100%);
    visibility: hidden;
    z-index: 3;
    left: 0;
    top: 100%;
    width: calc(100vw);
    padding: 0;
    border: 0.1rem solid rgba(var(--color-foreground), 0.2);
    border-left: 0;
    background-color: rgb(var(--color-background));
    overflow-x: hidden;
    top: 70px;
    transition: 0.3s all ease-in-out;
  }
  .menu-drawer-container {
    position: relative;
  }
  .menu-drawer.open {
    transform: translate(-5%);
    visibility: visible;
    height: 100vh;
    background-color: #ffffff;
  }
  .menu-drawer-inner-contaienr {
    position: relative;
    height: 100%;
  }
  .menu-drawer-navigation-container {
    display: grid;
    grid-template-rows: 1fr auto;
    align-content: space-between;
    overflow-y: auto;
    height: 100%;
    z-index: 10;
  }
  .menu-drawer-navigation {
    padding: 5.6rem 0;
  }
  .Nav-container ul {
    flex-direction: column;
  }
  .Nav-container ul li {
    padding: 1.1rem 3.2rem;
    margin: 0.2rem 0;
  }
}

@media screen and (max-width: 380px) {
  .Nav-container {
    column-gap: 0.5rem;
  }
}
@media screen and (min-width: 748px) {
  .Nav-right-con .header_icons:nth-child(3) {
    display: flex;
  }
}

@media screen and (min-width: 1024px) {
  .header-drawer {
    display: none;
  }
  .Navlinks-container {
    display: block;
  }
  .header_icons {
    height: 2rem;
    width: 3rem;
  }
}

.Banner-section {
  display: flex;
  flex-direction: column-reverse;

  max-width: 1730px;
  gap: 2rem;
}

.banner-left-con img.tablet-up {
  display: none;
}

.banner-left-con img.mobile {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
}
.banner-right-wrapper {
  margin-bottom: 15px;
  width: 100%;
  padding: 15px;
  text-align: center;
}
.banner-right-wrapper img {
  width: 100%;
  height: auto;
}
.banner-right-wrapper p.title {
  font-size: var(--unnamed-font-size-32);
  color: var(--neutral);
  line-height: 40px;
  font-family: var(--unnamed-font-family-ibarra-real-nova);
  margin: 30px 0 20px;
}
.banner-right-wrapper p.desc {
  font-family: var(--unnamed-font-family-red-hat-display);
  font-size: 20px;
  line-height: 42px;
  margin-bottom: 30px;
}
@media (min-width: 767px) {
  .banner-left-con img.tablet-up {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .banner-left-con img.mobile {
    display: none;
  }
  .Banner-section {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@media (min-width: 1024px) {
  .Banner-section {
    min-height: 100%;
    gap: 13.7rem;
  }
}
@media (min-width: 1024px) {
  .Banner-section {
    gap: 5rem;
  }
}
@media (min-width: 1500px) {
  .Banner-section {
    gap: 13.7rem;
  }
}

/* home section two */
.Home-section-two {
  margin: 40px auto;
}
.section-two-heading {
  text-align: center;
  max-width: 750px;
  margin-inline: auto;
}
.section-two-heading h2 {
  font-family: var(--unnamed-font-family-ibarra-real-nova);
  font-size: var(--unnamed-font-size-28);
  color: var(--neutral);
  line-height: 35px;
}
.section-two-heading p {
  font-size: var(--unnamed-font-size-14);
  line-height: 25px;
  color: var(--neutral);
  margin-block: 20px;
}
.section-two-main-container img {
  height: auto;
  object-fit: cover;
}
.category-details {
  text-align: center;
}
.section-two-main-container h3 {
  margin-block: 20px 10px;
  font-size: var(--unnamed-font-size-28);
  font-family: var(--unnamed-font-family-ibarra-real-nova);
  line-height: 35px;
}
.section-two-main-container > div {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section-two-main-container div:last-child {
  margin-bottom: 0;
}
@media (min-width: 767px) {
  .section-two-main-container {
    display: flex;
    gap: 10px;
  }
  .section-two-main-container > div {
    margin-bottom: 0;
  }
  .section-two-main-container img {
    width: 752px;
  }
  .section-two-main-container h3 {
    font-size: var(--unnamed-font-size-32);
    line-height: 40px;
  }
}

/* swiperCSS */
.swiper-button-next::after {
  content: '';
  background-image: url('./assets/icons/ArrowRight.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 9px;
  height: 16px;
}

.swiper-button-prev::after {
  content: '';
  background-image: url('./assets/icons/ArrowLeft.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 9px;
  height: 16px;
}
.slides-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.card-wrapper {
  margin-top: 15px;
}
.card-wrapper p {
  font-size: var(--unnamed-font-size-14);
  line-height: 14px;
}
.card-wrapper p:first-child {
  margin-bottom: 10px;
}
.slides-card img {
  height: auto;
}
.swiper-container .swiper {
  padding-bottom: 30px;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0;
}
@media (min-width: 767px) {
  .slides-card img {
    width: 371px;
    object-fit: cover;
  }
  .card-wrapper {
    margin-top: 20px;
  }
  .card-wrapper p {
    line-height: 24px;
  }
}
@media (max-width: 989px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
  .swiper-pagination {
    display: none;
  }
  .swiper-container .swiper {
    padding-bottom: 0;
  }
}

/* home section four */
.section-four-main-container {
  margin-block: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sec-four-left-container img {
  height: auto;
  margin: 0 auto;
  object-fit: cover;
}
.sec-four-right-container img {
  height: auto;
  object-fit: cover;
}
.ice-collection-content-wrap {
  text-align: center;
  margin-block: 30px;
}
.ice-collection-content-wrap h2 {
  font-size: var(--unnamed-font-size-28);
  line-height: 35px;
  font-family: var(--unnamed-font-family-ibarra-real-nova);
}
.ice-collection-content-wrap p {
  font-size: var(--unnamed-font-size-14);
  line-height: 25px;
  margin-block: 20px 30px;
}
.Home-section-five {
  margin-block: 40px;
}
@media (min-width: 767px) {
  .section-four-main-container {
    margin-block: 80px;
  }
  .ice-collection-content-wrap {
    margin-block: 30px 0;
    margin-inline: auto;
    max-width: 398px;
  }
  .section-four-main-container {
    flex-direction: row;
  }
  .sec-four-left-container img {
    width: 75%;
    height: auto;
  }
  .sec-four-right-container img {
    width: 100%;
    height: 100%;
  }
  .sec-four-left-container {
    width: 45%;
    height: auto;
    padding: 0 10px;
  }
  .ice-collection-content-wrap h2 {
    font-size: var(--unnamed-font-size-32);
    line-height: 40px;
  }
  .ice-collection-content-wrap p {
    line-height: 25.2px;
  }
  .Home-section-five {
    margin-block: 80px;
  }
}
/* homesection six */
.Home-section-six {
  margin-block: 40px;
  text-align: center;
  padding: 0 15px;
}
.wrap-contianer {
  margin-block: 20px 15px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.insta-img-div img {
  width: 100%;
  height: auto;
}
.Home-section-six h2 {
  font-size: var(--unnamed-font-size-28);
  line-height: 35px;
  font-family: var(--unnamed-font-family-ibarra-real-nova);
}
@media (min-width: 767px) {
  .Home-section-six {
    margin-block: 80px;
    padding: 0 10px;
  }
  .wrap-contianer {
    margin-block: 15px;
    grid-template-columns: repeat(4, 1fr);
  }
  .insta-img-div img {
    height: 100%;
  }
  .Home-section-six h2 {
    line-height: 40px;
  }
}

/* section sever */
.Home-section-seven {
  padding: 60px 15px;
  text-align: center;
  background-color: var(--neutral-02);
}
.sec-seven-content-wrap h2 {
  font-family: var(--unnamed-font-family-ibarra-real-nova);
  font-size: var(--unnamed-font-size-28);
  line-height: 35px;
}
.sec-seven-content-wrap p {
  font-size: var(--unnamed-font-size-14);
  line-height: 25px;
  margin: 30px 0 20px;
}
.sec-seven-content-wrap label {
  display: none;
}
.sec-seven-content-wrap input {
  border: 1px solid var(--color-custom-grey);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1.4px;
  padding: 15px 16px;
  max-width: 371px;
  width: 100%;
}
.sec-seven-content-wrap input::placeholder {
  font-size: var(--unnamed-font-size-14);
  line-height: 18px;
  letter-spacing: 1.4px;
}
.sec-seven-content-wrap .btn-cta {
  max-width: 371px;
  width: 100%;
  margin-top: 10px;
  margin-left: 0px;
}
@media (min-width: 767px) {
  .sec-seven-content-wrap h2 {
    font-size: var(--unnamed-font-size-32);
    line-height: 40px;
  }
  .sec-seven-content-wrap .btn-cta {
    margin-left: 10px;
  }
}

/* footer */
.footer-content-container {
  padding: 60px 40px;
  display: grid;
  gap: 10px;
}

.footer-content-wrap ul {
  list-style: none;
  margin-bottom: 40px;
}

.footer-content-wrap ul li {
  font-size: var(--unnamed-font-size-14);
  line-height: 28px;
  text-transform: capitalize;
}
.footer-content-wrap ul li:not(:last-child) {
  margin-bottom: 20px;
}
li.social-media {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 25%;
}
.social-media a {
  font-size: 20px;
  line-height: 28px;
  text-transform: capitalize;
}
.footer-content-wrap span h2 {
  letter-spacing: 2.1px;
  line-height: 25.2px;
  text-transform: uppercase;
  font-weight: 800;
  margin-bottom: 2rem;
  font-size: var(--unnamed-font-size-14);
  position: relative;
}

@media (max-width: 766px) {
  .footer-content-wrap span h2::after {
    content: '\002B';
    position: absolute;
    right: 0;
    font-size: 25px;
  }
  .footer-content-wrap span.active h2::after {
    content: '\2212';
    position: absolute;
  }
  .footer-content-wrap span .footer-ul {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height 1s ease-out;
    -o-transition: max-height 1s ease-out;
    transition: max-height 1s ease-out;
    margin-bottom: 0;
  }
  .footer-content-wrap span.active .footer-ul {
    height: auto;
    max-height: 500px;
    -webkit-transition: max-height 1s ease-in !important;
    -o-transition: max-height 1s ease-in !important;
    transition: max-height 1s ease-in !important;
    margin-bottom: 4rem;
  }
}

@media (min-width: 767px) {
  .footer-content-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1024px) {
  .footer-content-container {
    padding: 60px 0;
    grid-template-columns: repeat(4, 1fr);
  }
}
